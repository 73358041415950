// // Import all channels.
const channels = import.meta.globEager("../channels/**/*_channel.js");

import '@rails/ujs'
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
Turbolinks.start();
ActiveStorage.start();

import consumer from "../channels/consumer";

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

// // Import all stimulus.
const application = Application.start();
const controllers = import.meta.globEager("../controllers/**/*_controller.js");
registerControllers(application, controllers);
application.consumer = consumer;

// document.addEventListener("turbolinks:load", () => {
//   const ckeditor = document.querySelector(".ckeditor");
//   if (ckeditor) {
//     ClassicEditor.create(ckeditor, {
//       simpleUpload: { uploadUrl: "/pictures" },
//     });
//   }
// });
